'use client';

import { type Node } from '@react-types/shared';
import classNames from 'classnames';
import { useRef } from 'react';
import {
  type AriaTabListProps,
  type AriaTabPanelProps,
  useTab,
  useTabList,
  useTabPanel,
} from 'react-aria';
import Skeleton from 'react-loading-skeleton';
import { Item, useTabListState, type TabListState } from 'react-stately';

import { Button } from '@module/mdrt-org/shared/components/button';
import { ButtonVariant } from '@shared/ui-components';

import styles from './membership-toolkit-tabs-section.module.scss';

type LinksList = {
  links: Array<{
    displayText: string;
    downloadFileName: string;
    linkToContent: string;
    target: string;
  }>;
  title: string;
};

type TabsData = {
  description: string;
  firstListSection: LinksList | null;
  fourthListSection: LinksList | null;
  key: string;
  secondListSection: LinksList | null;
  thirdListSection: LinksList | null;
  title: string;
};

const Tab = ({ item, state }: { item: Node<TabsData>; state: TabListState<TabsData> }) => {
  const { key, value } = item;
  const ref = useRef(null);
  const { isSelected, tabProps } = useTab({ key }, state, ref);

  return (
    <button
      className={classNames(styles['membership-toolkit-tabs__tab-item'], {
        [styles['membership-toolkit-tabs__tab-item--theme-light']]: !isSelected,
        [styles['membership-toolkit-tabs__tab-item--theme-active']]: isSelected,
      })}
      data-testid="membership-toolkit-tab-item"
      key={value?.key}
      ref={ref}
      type="button"
      {...tabProps}
    >
      {value?.title}
    </button>
  );
};

const TabPanel = ({ state, ...props }: AriaTabPanelProps & { state: TabListState<TabsData> }) => {
  const ref = useRef(null);
  const { tabPanelProps } = useTabPanel(props, state, ref);

  return (
    <div
      className={styles['membership-toolkit-tabs__content']}
      data-testid="membership-toolkit-tab-content"
      ref={ref}
      {...tabPanelProps}
    >
      {state.selectedItem?.props.children}
    </div>
  );
};

const Tabs = (props: AriaTabListProps<TabsData>) => {
  const state = useTabListState(props);
  const ref = useRef(null);
  const { tabListProps } = useTabList(props, state, ref);

  return (
    <div className={styles['membership-toolkit-tabs']}>
      {state.selectedItem ? (
        <div
          className={styles['membership-toolkit-tabs__list']}
          data-testid="membership-toolkit-tab-navigation"
          ref={ref}
          {...tabListProps}
        >
          {[...state.collection].map((item) => (
            <Tab item={item} key={item.key} state={state} />
          ))}
        </div>
      ) : (
        <div className={styles['membership-toolkit-tabs__list']}>
          {Array.from({ length: 6 }, (_, index) => index + 1).map((key) => (
            <Skeleton
              borderRadius={24}
              containerClassName={styles['membership-toolkit-tabs__item-skeleton']}
              height={39}
              key={key}
            />
          ))}
        </div>
      )}
      {state.selectedItem ? (
        <TabPanel key={state.selectedItem?.key} state={state} />
      ) : (
        <div className={styles['membership-toolkit-tabs__content']}>
          <Skeleton height={32} width={200} />
          <Skeleton
            containerClassName={styles['membership-toolkit-tabs__description-skeleton']}
            height={90}
          />
          <div className={styles['membership-toolkit-links-list']}>
            <Skeleton
              containerClassName={styles['membership-toolkit-links-list__item-skeleton']}
              height={120}
            />
            <Skeleton
              containerClassName={styles['membership-toolkit-links-list__item-skeleton']}
              height={120}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const LinksListSection = ({ list }: { list: LinksList | null }) => {
  if (list === null || list.links.length === 0) {
    return null;
  }

  return (
    <div className={styles['membership-toolkit-links-list__item']}>
      {list.title && (
        <h4 className={styles['membership-toolkit-links-list__title']}>{list.title}</h4>
      )}
      {list.links.length > 0 && (
        <ul>
          {list.links
            .filter((link) => link.displayText)
            .map((link) => (
              <li key={link.displayText}>
                <Button
                  {...(link.downloadFileName && { download: link.downloadFileName ?? '' })}
                  isSmall
                  target={link.target}
                  to={link.linkToContent}
                  variant={ButtonVariant.Tertiary}
                >
                  {link.displayText}
                </Button>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

export const MembershipToolkitTabsSection = ({
  tabsAriaLabel,
  tabsData,
}: {
  tabsAriaLabel: string;
  tabsData: TabsData[];
}) => {
  if (!tabsData) return null;

  return (
    <Tabs aria-label={tabsAriaLabel} items={tabsData}>
      {(itemData) => {
        const {
          description,
          firstListSection,
          fourthListSection,
          secondListSection,
          thirdListSection,
          title,
        } = itemData;

        return (
          <Item>
            <h3>{title}</h3>
            {description && (
              <p className={styles['membership-toolkit-tabs__description']}>{description}</p>
            )}
            <div className={styles['membership-toolkit-links-list']}>
              <LinksListSection list={firstListSection} />
              <LinksListSection list={secondListSection} />
              <LinksListSection list={thirdListSection} />
              <LinksListSection list={fourthListSection} />
            </div>
          </Item>
        );
      }}
    </Tabs>
  );
};
